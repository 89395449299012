import React from 'react';
import { Route, Routes } from 'react-router';
import { useSelector } from 'react-redux';
import { isUndefined } from './utils/common_utils';

import Login from './components/pages/login/login';
import Custom404 from './components/pages/errors/404';
import Custom403 from './components/pages/errors/403';
import Custom500 from './components/pages/errors/500';
import Fallback from './components/fallback/fallback';
import { BrowserRouter } from 'react-router-dom';
import StoreState from './types/securityTypes';

const Dashboard = React.lazy(() => import('./components/pages/secured/dashboard/dashboard'));
const Calendar = React.lazy(() => import('./components/pages/secured/reservation/calendar'));
const Reports = React.lazy(() => import('./components/pages/secured/reservation/report'));
const TermOverview = React.lazy(() => import('./components/pages/secured/terms/overview'));
const TermGenerator = React.lazy(() => import('./components/pages/secured/terms/generator'));
const Customers = React.lazy(() => import('./components/pages/secured/customers/customers'));
const CustomerCreate = React.lazy(() => import('./components/pages/secured/customers/customerCreate'));
const CustomerDetail = React.lazy(() => import('./components/pages/secured/customers/detail'));
const Setting = React.lazy(() => import('./components/pages/secured/setting/setting'));
const ServiceDetail = React.lazy(() => import('./components/pages/secured/setting/service/detail'));
const EmployeeDetail = React.lazy(() => import('./components/pages/secured/setting/employee/detail'));
const ServiceCreate = React.lazy(() => import('./components/pages/secured/services/serviceCreate'));
const EmployeeCreate = React.lazy(() => import('./components/pages/secured/employees/employeeCreate'));

const App = () => {
    const { token, isAdmin } = useSelector((state: StoreState) => state.auth);

    const renderAdminRoutes = () => {
        return (
            <>
                <Route element={<React.Suspense fallback={<Fallback loading />}><Reports /></React.Suspense>} path="/secured/reservations/report" />
                <Route element={<React.Suspense fallback={<Fallback loading />}><Setting /></React.Suspense>} path="/secured/setting" />
                <Route element={<React.Suspense fallback={<Fallback loading />}><ServiceDetail /></React.Suspense>} path="/secured/setting/services/detail/:id" />
                <Route element={<React.Suspense fallback={<Fallback loading />}><EmployeeDetail /></React.Suspense>} path="/secured/setting/employees/detail/:id" />
                <Route element={<React.Suspense fallback={<Fallback loading />}><ServiceCreate /></React.Suspense>} path="/secured/setting/services/create" />
                <Route element={<React.Suspense fallback={<Fallback loading />}><EmployeeCreate /></React.Suspense>} path="/secured/setting/employees/create" />
            </>
        )
    }    
    return (
        <BrowserRouter>
            <Routes>
                {!isUndefined(token)
                    ? <React.Fragment>
                        {isAdmin && renderAdminRoutes()}
                        <Route element={<React.Suspense fallback={<Fallback loading />}><Calendar /></React.Suspense>} path="/secured/reservations/calendar" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><TermOverview /></React.Suspense>} path="/secured/terms/overview" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><TermGenerator /></React.Suspense>} path="/secured/terms/generator" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><Customers /></React.Suspense>} path="/secured/customers" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><CustomerCreate /></React.Suspense>} path="/secured/customers/create" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><CustomerDetail /></React.Suspense>} path="/secured/customers/detail/:id" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><Custom403 /></React.Suspense>} path="/403" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><Custom500 /></React.Suspense>} path="/500" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><Custom404 /></React.Suspense>} path="/404" />
                        <Route element={<React.Suspense fallback={<Fallback loading />}><Dashboard /></React.Suspense>} path="*" />
                      </React.Fragment>
                    : <Route element={<Login />} path="*" />}
            </Routes>
         </BrowserRouter>
    );
}

export default App;