import { logout } from '../redux/authSlice';
import { getStore, getThunkDispatch } from '../redux/store';
import { createMessage, createNotification, isUndefined } from './common_utils';
import axios from 'axios';


const instance = axios.create({
    //baseURL: 'http://192.168.0.25:5000/'
    baseURL: 'https://test.be.rezervathor.com'
});


instance.interceptors.request.use(request => {
    const session = getStore().getState()?.auth;
    const token = session.token;
    const expirationDate = typeof session?.expirationDate === 'string' ? Date.parse(session?.expirationDate) : session?.expirationDate;
    
    if (expirationDate !== undefined && expirationDate < new Date() && !isUndefined(token)) {
        createMessage("warning", "Vypršela platnost vaši relace. Pro pokračování se prosím znovu přihlašte.");
        getThunkDispatch()(logout());
    } else if (!isUndefined(token) && expirationDate !== undefined && expirationDate > new Date()) {
        request.headers.setAuthorization("Bearer " + token);
    }
    return request;
});

instance.interceptors.response.use(response => {
    const session = getStore().getState()?.auth;

    if (response.data.status === 'error' && !isUndefined(session.token)) {
        createNotification(response.data.status, response.data.message, "Neočekávaná chyba aplikace");
        getThunkDispatch()(logout());
    }
    return response;
});

export default instance;